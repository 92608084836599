import React, {useContext, useEffect, useState} from "react";
import {StringParam, useQueryParam} from "use-query-params";
import {Asset} from "../models/Asset";
import {AssetCollection} from "../models/AssetCollection";
import {WebsiteSliderItem} from "../models/web/WebsiteSliderItem";
import {RootContext} from "../root";
import {FirebaseContext} from "./firebase";

// TAssetContext represents the data type that this
// provider will inject in its decendents.
interface TAssetContext {
  asset: Asset | undefined;
  collection: AssetCollection | undefined;
  loading: boolean | undefined;
  collectionId: string | undefined;
  serialNumber: string | undefined;
  qrSuffix: string | undefined;
}

export const AssetContext = React.createContext<TAssetContext>({
  asset: undefined,
  collection: undefined,
  loading: true,
  collectionId: undefined,
  serialNumber: undefined,
  qrSuffix: undefined
});

/*
 * The asset provider is responsible for querying the backend if
 * a collection ID and serial number are given. If the asset plus
 * its associated collection are already loaded it does nothing.
 * If either is not given, it does nothing.
 */
export const AssetProvider = ({children}) => {
  const [collectionId] = useQueryParam("collectionId", StringParam);
  const [serialNumber] = useQueryParam("serialNumber", StringParam);
  const [qrSuffixQueryParameter] = useQueryParam("qrSuffix", StringParam);
  const {setError} = useContext(RootContext);
  const {firebase} = useContext(FirebaseContext);
  const [data, setData] = useState<{
    asset: Asset;
    collection: AssetCollection;
    collectionId: string | undefined;
    serialNumber: string | undefined;
    qrSuffix: string | undefined;
  }>(null);

  // If the data was already loaded default to `loading` == false
  const [loading, setLoading] = useState(
    Boolean(!data && collectionId && serialNumber)
  );

  /**
   * Checks for an active countdown on the corresponding collection and
   * redirects to home if it exists.
   * @param websiteSliderItem
   */
  const checkForActiveCountdown = (websiteSliderItem: WebsiteSliderItem) => {
    const now = new Date();
    if (
      websiteSliderItem.countdownAfter &&
      now > new Date(websiteSliderItem.countdownAfter) &&
      websiteSliderItem.visibleAfter &&
      now < new Date(websiteSliderItem.visibleAfter)
    ) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    // Expect it to be the last query param without a value
    const searchParams = new URLSearchParams(location.search);
    let qrSuffix: string | undefined = qrSuffixQueryParameter;
    if (!qrSuffix) {
      for (var searchParam of searchParams.entries()) {
        if (searchParam.length === 2 && searchParam[1].length === 0) {
          qrSuffix = searchParam[0];
          console.debug(`Detected secret: ${qrSuffix}`);
          break;
        }
      }
    }
    const fetchAsset = async () => {
      try {
        const firestore = firebase.firestore();

        setLoading(data === null);
        setError(null);

        const [assetResult, collectionResult] = await Promise.all([
          firestore
            .collection("assets")
            .doc(`${collectionId}_${serialNumber}`)
            .get(),
          firestore.collection("assetCollections").doc(collectionId).get()
        ]);

        if (!assetResult.exists) {
          throw new Error(
            `Asset not found for collection ID ${collectionId} and serial number ${serialNumber}`
          );
        }

        if (!collectionResult.exists) {
          throw new Error(
            `Asset collection not found for collection ID ${collectionId}`
          );
        }

        const assetCollection = collectionResult.data() as AssetCollection;
        const asset = assetResult.data() as Asset;

        console.log({asset, assetCollection, qrSuffix});
        setData({
          asset: asset,
          collection: assetCollection,
          collectionId: collectionId,
          serialNumber: serialNumber,
          qrSuffix: qrSuffix
        });
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Only query the backend if the asset was not already loaded.
    // This is necessary to not fetch the asset again when navigating
    // back and forth to the assets page.
    if (!data && collectionId && serialNumber && firebase) {
      fetchAsset();
    }
  }, [collectionId, serialNumber, firebase]);

  return (
    <AssetContext.Provider
      value={{
        ...data,
        loading: loading
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
