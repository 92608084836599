export default {
  primary: "#252f4a",
  opacity: 0.5,
  letterSpacing: "1px",
  breakpoints: {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px"
  },
  breakpointsNo: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  media: {
    minWidthXs: `(min-width: 0)`,
    minWidthSm: `(min-width: 576px)`,
    minWidthMd: `(min-width: 768px)`,
    minWidthLg: `(min-width: 992px)`,
    minWidthXl: `(min-width: 1200px)`
  }
};
