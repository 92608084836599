// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assets-tsx": () => import("./../../../src/pages/assets.tsx" /* webpackChunkName: "component---src-pages-assets-tsx" */),
  "component---src-pages-claim-nft-tsx": () => import("./../../../src/pages/claim-nft.tsx" /* webpackChunkName: "component---src-pages-claim-nft-tsx" */),
  "component---src-pages-easter-eggs-details-tsx": () => import("./../../../src/pages/easter-eggs/details.tsx" /* webpackChunkName: "component---src-pages-easter-eggs-details-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nft-faq-tsx": () => import("./../../../src/pages/nft/faq.tsx" /* webpackChunkName: "component---src-pages-nft-faq-tsx" */),
  "component---src-pages-redir-tsx": () => import("./../../../src/pages/redir.tsx" /* webpackChunkName: "component---src-pages-redir-tsx" */),
  "component---src-pages-virtual-ownership-tsx": () => import("./../../../src/pages/virtual-ownership.tsx" /* webpackChunkName: "component---src-pages-virtual-ownership-tsx" */)
}

