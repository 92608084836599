import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState
} from "react";
import {WebsiteFAQItem} from "../models/web/WebsiteFaqItem";
import {FirebaseContext} from "./firebase";

// TFAQContext represents the data type that this
// provider will inject in its decendents.
interface TFAQContext {
  faqs: WebsiteFAQItem[];
  loading: boolean | undefined;
  error: any;
}

export const FAQContext = React.createContext<TFAQContext>({
  faqs: [],
  loading: true,
  error: undefined
});

/*
 * The FAQ provider is responsible for querying the backend
 * for the WebsiteFAQItems. If they are already loaded it does nothing.
 */
export const FAQProvider: FC = ({children}) => {
  const {firebase} = useContext(FirebaseContext);
  const [faqs, setFaqs] = useState<WebsiteFAQItem[]>(null);
  const [error, setError] = useState(null);

  // If the data was already loaded default to `loading` == false
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        setLoading(faqs === null);
        setError(null);

        const faqsResult = await firebase
          .firestore()
          .collection("websiteFaqItems")
          .where("status", "==", "LIVE")
          .get();        

        if (faqsResult.empty) {
          throw new Error("No FAQs found");
        }

        setFaqs(
          faqsResult.docs
            .map((faq) => faq.data() as WebsiteFAQItem)
            .sort(
              (a: WebsiteFAQItem, b: WebsiteFAQItem) => b.sortKey - a.sortKey
            )
        );
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Only query the backend if the FAQs was not already loaded.
    // This is necessary to not fetch the FAQs again when navigating
    // back and forth.
    if (!faqs && firebase) {
      fetchFAQs();
    }
  }, [firebase]);

  return (
    <FAQContext.Provider value={{faqs, loading, error}}>
      {children}
    </FAQContext.Provider>
  );
};
