/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/index.scss";
import withRoot from "./src/root";

export const wrapPageElement = withRoot;
