import {useState} from "react";
import Cookies, {CookieSetOptions} from "universal-cookie";

export const useCookie = (
  key: string,
  value: any,
  options: CookieSetOptions
) => {
  const cookies = new Cookies();

  const [cookie, setCookie] = useState(() => {
    if (cookies.get(key)) {
      return cookies.get(key);
    }
    if (value === null) {
      return;
    }
    cookies.set(key, value, options);
  });

  const updateCookie = (value: any, options: CookieSetOptions) => {
    cookies.remove(key);
    setCookie(value);
    if (value === null) {
      return;
    }
    cookies.set(key, value, options);
  };

  return [cookie, updateCookie];
};
