import React, {useContext} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {LanguageContext} from "../../provider/language";
import {RootContext} from "../../root";
import {Link} from "gatsby";
// @ts-ignore
import logo from "./philatelie_logo_weiss.svg";

const Container = styled.header`
  position: relative;
`;

const Img = styled.img<{centered?: boolean}>`
  height: 80px;
  margin: auto;
  margin-top: ${({centered}) => centered && "40px"};
`;

const CenteredImageWrapper = styled.div`
  height: 150px;
`;

const LangSelection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const LanguageSwitcher = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
`;

const LogoWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 40px;
`;

const Horizontal = styled.div`
  width: 1px;
  border-left: 1px solid white;
  height: 60px;
  margin: 5px 24px 0 24px;
`;

const Stamp40Small = styled.p`
  opacity: ${(props) => props.theme.opacity};
  text-align: center;
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: ${(props) => props.theme.letterSpacing};
  margin: 5px 0;
  padding-top: 5px;
`;

const Stamp40Large = styled.span`
  text-transform: uppercase;
  letter-spacing: ${(props) => props.theme.letterSpacing};
`;

const Header = () => {
  const intl = useIntl();
  const {location} = useContext(RootContext);
  const {languages, setLocale} = useContext(LanguageContext);
  const localeLinks = languages.map((language, idx, arr) => {
    const link = (
      <a
        href={location.pathname}
        onClick={(e) => {
          e.preventDefault();
          setLocale(language.locale);
        }}
      >
        {language.locale.toUpperCase()}
      </a>
    );
    const spacer = idx === arr.length - 1 ? "" : "|";
    return (
      <span key={idx}>
        <span className="mx-2">{link}</span>
        {spacer}
      </span>
    );
  });
  return (
    <Container className="container">
      <div className="d-block d-md-none">
        <Stamp40Small className="d-block d-md-none">
          <FormattedMessage id="header.stamp.4.0" />
        </Stamp40Small>
        <LanguageSwitcher>{localeLinks}</LanguageSwitcher>
      </div>
      <LangSelection className="d-none d-md-flex">
        <Stamp40Large className="text-uppercase">
          <FormattedMessage id="header.stamp.4.0" />
        </Stamp40Large>
        <Horizontal />
        <div>{localeLinks}</div>
      </LangSelection>
      <div className="row">
        <div className="col-12 d-md-none text-center">
          <Link to="/">
            <CenteredImageWrapper>
              <Img centered src={logo} alt="Philatelie Logo" />
            </CenteredImageWrapper>
          </Link>
        </div>
        <div className="d-none d-sm-none d-md-block col-md-4">
          <Link to="/">
            <LogoWrapper>
              <Img
                src={logo}
                alt={intl.formatMessage({id: "logo.philatelie.alt"})}
              />
            </LogoWrapper>
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Header;
