import React from "react";
import {languages, translations} from "../i18n/index";
import browserLang from "browser-lang";
import {IntlProvider} from "react-intl";
import {useCookie} from "../hooks/cookies";
import {CookieSetOptions} from "universal-cookie";

const cookieKey = "user_locale";
const cookieOptions: CookieSetOptions = {
  path: "/",
  sameSite: "strict",
  expires: new Date(Date.now() + 31536000) // 1 year
};

interface TLanguageContext {
  languages: {locale: string; default?: boolean}[];
  locale: string;
  setLocale: Function;
}

export const LanguageContext = React.createContext<TLanguageContext>(null);

/*
 * The language provider is responsible for determining the users
 * locale. It first looks for the current browser locale, if that
 * fails, then a faulback locale is used. Both settings are over-
 * written if the user chooses to set the locale him/her-self.
 */
export const LanguageProvider = ({children}) => {
  // Find default locale
  const defaultLocale = languages.find((language) => language.default).locale;

  // Load user defined locale from cookies
  const [userLocale, setUserLocale] = useCookie(cookieKey, null, cookieOptions);

  // Find user locale based on browser settings
  const browserLocale =
    typeof window !== "undefined"
      ? browserLang({
          languages: languages.map((language) => language.locale),
          fallback: defaultLocale
        })
      : defaultLocale;

  // Determine active locale
  const pageLocale = userLocale || browserLocale || defaultLocale;
  return (
    <LanguageContext.Provider
      value={{
        languages,
        locale: pageLocale,
        setLocale: (locale) => setUserLocale(locale, cookieOptions)
      }}
    >
      <IntlProvider locale={pageLocale} messages={translations[pageLocale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
