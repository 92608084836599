/**
 * Represents a hero slider item on the CryptoStamp website.
 *
 * Normally, one slider item exists for each asset edition.
 *
 * If the current time is within the window of countdownAfter and visibleAfter for the first
 * slide, instead of the slider a single image with countdown is visible.
 */
export interface WebsiteSliderItem {
  /**
   * Reference to asset edition information. Needed to decide in an asset detail website view
   * whether to redirect to homepage if countdown is active.
   */
  assetEdition?: WebsiteSliderItemAssetEdition;
  content: WebsiteSliderItemContent;
  /**
   * Optional ISO8601 date string that describes date and time after this item is visible with
   * a countdown. If this is the first slide and the time constraints are met, a single
   * countdown image is displayed.
   */
  countdownAfter?: string;
  /**
   * Optional CTA button configuration
   */
  ctaButton?: WebsiteSliderItemCtaButton;
  image: WebsiteSliderItemImage;
  /**
   * Slider items are sorted by this key in descending order.
   */
  sortKey: number;
  status: WebsiteItemStatus;
  /**
   * Optional ISO8601 date string that describes date and time after this item is visible,
   * without an optional countdown that has been set before.
   */
  visibleAfter?: string;
  /**
   * Optional ISO8601 date string that describes date and time until this item is visible.
   */
  visibleUntil?: string;
}

/**
 * Reference to asset edition information. Needed to decide in an asset detail website view
 * whether to redirect to homepage if countdown is active.
 */
export interface WebsiteSliderItemAssetEdition {
  /**
   * Corresponding collection variants. Used to build assetCollections query for
   * AssetEditionDetailScreen.
   */
  assetCollectionIds?: string[];
  /**
   * Asset edition ID
   */
  id?: string;
}

export interface WebsiteSliderItemContent {
  markdown?: I18NString;
  /**
   * Optional strings that are rendered as a tag cloud at fixed positions within the slide.
   */
  tagCloud?: WebsiteSliderItemTagCloud;
}

export interface I18NString {
  de: string;
  en: string;
}

/**
 * Optional strings that are rendered as a tag cloud at fixed positions within the slide.
 */
export interface WebsiteSliderItemTagCloud {
  de: string[];
  en: string[];
}

/**
 * Optional CTA button configuration
 */
export interface WebsiteSliderItemCtaButton {
  caption: I18NString;
  url: I18NURL;
}

export interface I18NURL {
  de: string;
  en: string;
}

export interface WebsiteSliderItemImage {
  altText: I18NString;
  url: string;
}

/**
 * Status of a website item like a FAQ entry or slider.
 */
export enum WebsiteItemStatus {
  Deleted = "DELETED",
  Draft = "DRAFT",
  Live = "LIVE",
}
