import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {createGlobalStyle, ThemeProvider} from "styled-components";
import Layout from "./layout";
import {AssetProvider} from "./provider/asset";
import {FAQProvider} from "./provider/faqs";
import {FirebaseProvider} from "./provider/firebase";
import {LanguageProvider} from "./provider/language";
import {SliderItemsProvider} from "./provider/slider-item";
import theme from "./theme";

interface HasError {
  error: any;
}

const GlobalStyle = createGlobalStyle<HasError>`
  body {
    background-image: ${(props) =>
      props.error
        ? "linear-gradient(67deg, #252f4a, #791b2c)"
        : "linear-gradient(to right, #252f4a, #3d476a)"};
  }
`;

interface TRootContext {
  error: any;
  setError: Function;
  location: Location;
  /* Whether TS particles currently is active on a page. Used to invert logos, e.g. */
  toggleLogos: any;
  logosInverted: boolean;
}

export const RootContext = React.createContext<TRootContext>(null);

const Root = ({element, props}) => {
  const [error, setError] = useState(null);

  const [invertedLogos, setInvertedLogos] = useState(false);

  const pageContextValue = {
    setError,
    error,
    location: props.location,
    toggleLogos: () => {
      console.info("toggleLogos() invoked.");
      setInvertedLogos(!invertedLogos);
    },
    logosInverted: invertedLogos
  };

  return (
    <RootContext.Provider value={pageContextValue}>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <FirebaseProvider>
            <AssetProvider>
              <SliderItemsProvider>
                <FAQProvider>
                  <GlobalStyle error={error} />
                  <Helmet>
                    <link
                      rel="apple-touch-icon"
                      sizes="57x57"
                      href="/favicons/apple-icon-57x57.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="60x60"
                      href="/favicons/apple-icon-60x60.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="72x72"
                      href="/favicons/apple-icon-72x72.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="76x76"
                      href="/favicons/apple-icon-76x76.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="114x114"
                      href="/favicons/apple-icon-114x114.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="120x120"
                      href="/favicons/apple-icon-120x120.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="144x144"
                      href="/favicons/apple-icon-144x144.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="152x152"
                      href="/favicons/apple-icon-152x152.png"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="180x180"
                      href="/favicons/apple-icon-180x180.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="192x192"
                      href="/favicons/android-icon-192x192.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="32x32"
                      href="/favicons/favicon-32x32.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="96x96"
                      href="/favicons/favicon-96x96.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="16x16"
                      href="/favicons/favicon-16x16.png"
                    />
                    <meta name="msapplication-TileColor" content="#234323" />
                    <meta
                      name="msapplication-TileImage"
                      content="/ms-icon-144x144.png"
                    />
                    <meta name="theme-color" content={theme.primary} />
                  </Helmet>
                  <Layout>{element}</Layout>
                </FAQProvider>
              </SliderItemsProvider>
            </AssetProvider>
          </FirebaseProvider>
        </ThemeProvider>
      </LanguageProvider>
    </RootContext.Provider>
  );
};

const Wrapper = ({element, props}) => {
  return <Root element={element} props={props} />;
};

export default Wrapper;
