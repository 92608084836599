import React, {useEffect, useState} from "react";
import * as firebaseInstance from "firebase";

// TFirebaseContext represents the data type that this
// provider will inject in its decendents.
interface TFirebaseContext {
  firebase: typeof firebaseInstance.default;
}

export const FirebaseContext = React.createContext<TFirebaseContext>({
  firebase: null
});

export const FirebaseProvider = ({children}) => {
  const [firebase, setFirebase] =
    useState<typeof firebaseInstance.default>(null);

  useEffect(() => {
    if (firebase || typeof window == "undefined") {
      return;
    }

    const app = import("firebase/app");
    const analytics = import("firebase/analytics");
    const firestore = import("firebase/firestore");

    Promise.all([app, analytics, firestore]).then(([firebase]) => {
      const firebaseConfig = {
        apiKey: process.env.GATSBY_API_KEY,
        authDomain: process.env.GATSBY_AUTH_DOMAIN,
        projectId: process.env.GATSBY_PROJECT_ID,
        storageBucket: process.env.GATSBY_STORAGE_BUCKET,
        messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
        appId: process.env.GATSBY_APP_ID,
        measurementId: process.env.GATSBY_MEASUREMENT_ID
      };
      firebase.default.initializeApp(firebaseConfig);
      firebase.default.analytics();
      console.log("Firebase initialized");
      setFirebase(firebase.default);
    });
  }, []);

  return (
    <FirebaseContext.Provider value={{firebase}}>
      {children}
    </FirebaseContext.Provider>
  );
};
