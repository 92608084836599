// This file needs the commonjs syntax because it's imported in gatsby-node.js
const localeDataDE = require("./de.json");
const localeDataEN = require("./en.json");

module.exports = {
  languages: [
    {
      locale: "de",
      default: true
    },
    {
      locale: "en"
    }
  ],
  translations: {
    de: localeDataDE,
    en: localeDataEN
  }
};
