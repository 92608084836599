import React, { useContext, useEffect } from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
// @ts-ignore
import dynamicelementLogoDark from "./dynamicelement-logo-dark.svg";
// @ts-ignore
import originstampLogoDark from "./originstamp-logo-landscape-dark.svg";
// @ts-ignore
import originstampLogoWhite from "./originstamp-logo-landscape-white.svg";
// @ts-ignore
import philatelie from "./philatelie.svg";
// @ts-ignore
import pli from "./pli.svg";
import { RootContext } from "../../root";
import "./index.css";

const Logo = styled.img`
  height: 67px;
  width: 100%;
  object-fit: contain;
`;

const SponsorContainer = styled.div`
  background-image: linear-gradient(to right, #b5bed4, #ffffff 55%);
`;

const GreyParagrph = styled.p`
  color: #636363;
`;

export default () => {
  const intl = useIntl();
  const {logosInverted} = useContext(RootContext);

  useEffect(() => {
    console.info(`Logos inverted: ${logosInverted}`)
  }, [logosInverted]);

  return (
    <>
      <SponsorContainer className="pt-4">
        <div className="container pt-5 mt-pd-5 pb-3">
          <div className="row">
            <div className="col-12 col-md-3 text-center mb-4 my-md-0">
              <a
                href={intl.formatMessage({id: "link.philatelie"})}
                target="__blank"
              >
                <Logo
                  src={philatelie}
                  className={logosInverted ? "filter-white" : ""}
                  alt={intl.formatMessage({id: "logo.philatelie.alt"})}
                />
              </a>
            </div>
            <div className="col-12 col-md-3 text-center my-4 my-md-0">
              <a href="https://www.post.li" target="__blank">
                <Logo
                  src={pli}
                  className={logosInverted ? "filter-white" : ""}
                  alt={intl.formatMessage({id: "logo.pli.alt"})}
                />
              </a>
            </div>
            <div className="col-12 col-md-3 text-center my-4 my-md-0">
              <a href="http://www.dynamicelement.com/" target="__blank">
                <Logo
                  src={dynamicelementLogoDark}
                  className={logosInverted ? "filter-white" : ""}
                  alt={intl.formatMessage({id: "logo.dynamic.element.alt"})}
                />
              </a>
            </div>
            <div className="col-12 col-md-3 text-center my-4 my-md-0">
              <a href="https://originstamp.com" target="__blank">
                <Logo
                  src={logosInverted ? originstampLogoWhite : originstampLogoDark}
                  alt={intl.formatMessage({id: "logo.originstamp.alt"})}
                />
              </a>
            </div>
          </div>
          <div className={"row justify-content-center"}>
            <div className={(logosInverted ? "invisible" : "") + " col-10 col-md-12"}>
              <div className="mb-5 mt-3 my-md-5 rfs-small">
                <GreyParagrph className="text-center mb-1">
                  <FormattedMessage id="sponsors.subtext.1" /> -{" "}
                  <a
                    href={intl.formatMessage({id: "link.shop"})}
                    className="text-turquoise"
                    target="__blank"
                  >
                    <FormattedMessage id="link.buy.now" />
                  </a>
                </GreyParagrph>
                <GreyParagrph className="text-center">
                  <FormattedMessage id="sponsors.subtext.2" />{" "}
                  <a
                    href="https://www.dynamicelement.com"
                    className="text-turquoise"
                    target="__blank"
                  >
                    <FormattedMessage id="company.dynamic.element" />
                  </a>{" "}
                  <FormattedMessage id="plain.and" />{" "}
                  <a
                    href="https://originstamp.com"
                    className="text-turquoise"
                    target="__blank"
                  >
                    <FormattedMessage id="company.originstamp" />
                  </a>
                  .
                </GreyParagrph>
              </div>
            </div>
          </div>
        </div>
      </SponsorContainer>
    </>
  );
};
