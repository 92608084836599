import React, {FunctionComponent as FC} from "react";
import Header from "./components/header/index";
import Sponsors from "./components/sponsors";
import Footer from "./components/footer/index";

type LayoutProps = {};

const Layout: FC<LayoutProps> = ({children}) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Sponsors />
      <Footer />
    </>
  );
};

export default Layout;
