import React, {FunctionComponent as FC, useContext, useEffect, useState} from "react";
import {WebsiteItemStatus, WebsiteSliderItem} from "../models/web/WebsiteSliderItem";
import {FirebaseContext} from "./firebase";

// TFigureContext represents the data type that this
// provider will inject in its decendents.
interface TSliderItemContext {
  sliderItems: WebsiteSliderItem[] | undefined;
  loading: boolean | undefined;
  error: any;
}

export const SliderItemContext = React.createContext<TSliderItemContext>({
  sliderItems: undefined,
  loading: true,
  error: undefined
});

const backupSliderItem: WebsiteSliderItem = {
  sortKey: 1,
  assetEdition: {
    id: "2021-09-weitblick",
    assetCollectionIds: ["bm4", "bi4"]
  },
  countdownAfter: "2021-08-01T00:00:00+0000",
  visibleAfter: "2021-09-04T00:00:00+0000",
  image: {
    url: "./blockchain-background.jpg",
    altText: {
      de: "Kryptobriefmarke Kollektion Weitblick",
      en: "Cryptostamp Collection Weitblick"
    }
  },
  content: {
    tagCloud: {
      de: ["fälschungssicher", "postgültig", "interaktiv", "spannend"],
      en: ["forgery-proof", "post valid", "interactive", "exciting"]
    }
  },
  ctaButton: {
    caption: {
      de: "Was ist die Briefmarke 4.0",
      en: "What is the Stamp 4.0"
    },
    url: {
      de: "/#what-is-the-stamp-40",
      en: "/#what-is-the-stamp-40"
    }
  },
  status: WebsiteItemStatus.Live
};

/*
 * The slideritems provider is responsible for querying the backend
 * for the websiteSliderItems. If they are already loaded it does nothing.
 */
export const SliderItemsProvider: FC = ({children}) => {
  const {firebase} = useContext(FirebaseContext);
  const [slideritems, setSlideritems] = useState<WebsiteSliderItem[]>(null);
  const [error, setError] = useState<WebsiteSliderItem[]>(null);

  // If the data was already loaded default to `loading` == false
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSliderItems = async () => {
      try {
        setLoading(slideritems === null);
        setError(null);

        const slideritemsResult = await firebase
          .firestore()
          .collection("websiteSliderItems")
          .where("status", "==", "LIVE")
          .get();

        // If the slider is empty for some reason a backupSliderItem is inserted.
        if (slideritemsResult.empty) {
          setSlideritems([backupSliderItem]);
        } else {
          setSlideritems(
            slideritemsResult.docs
              .map((slideritem) => slideritem.data() as WebsiteSliderItem)
              .sort((a: WebsiteSliderItem, b: WebsiteSliderItem) => b.sortKey - a.sortKey)
          );
        }
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Only query the backend if the slideritems were not already loaded.
    // This is necessary to not fetch the slideritems again when navigating
    // back and forth.
    if (!slideritems && firebase) {
      fetchSliderItems();
    }
  }, [firebase]);

  return (
    <SliderItemContext.Provider
      value={{sliderItems: slideritems, loading, error}}
    >
      {children}
    </SliderItemContext.Provider>
  );
};
